.faq{
    width: 80%;
}

.faq .questions-title{
    width: 100%;
    padding: 16px 4rem;
}

.faq .questions-title h1{
    font-size: 24px;
}

.faq .questions-title .questions{
    width: 100%;
    margin-top: 24px;
}

.faq .questions-title .questions .question{
    width: 100%;
    background-color: #d1e0ee;
    margin-top: 16px;
    padding: 16px;
    cursor: pointer;
}

.faq .questions-title .questions .question .top{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
}

.faq .questions-title .questions .question .top h3{
    font-size: 16px;
    font-weight: 600;
}

.faq .questions-title .questions .question .top .question-icon{
    color: #2487D9;
    cursor: pointer;
}

.faq .questions-title .questions .question .answer{
    width: 100%;
    margin-top: 4px;
    font-size: 15px;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.faq .questions-title .questions .question .answer.show{
    height: auto;
    max-height: 9999px;
    transition: all 0.75s cubic-bezier(1, 0, 1, 0);
}

@media screen and (max-width: 650px){
    .faq .questions-title{
        padding: 1rem;
    }
    .faq .questions-title h1{
        font-size: 20px;
    }
    .faq .questions-title .questions .question .top h3{
        font-size: 14px;
        font-weight: 600;
    }
    .faq .questions-title .questions .question .answer{
        font-size: 12px;
    }
}

@media screen and (max-width: 475px){
    .faq .questions-title .questions .question .top h3{
        font-size: 13px;
        font-weight: 600;
    }
    .faq .questions-title .questions .question .answer{
        font-size: 12px;
    }
}