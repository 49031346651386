.cusBody{
    margin: 16px;
}
.activegame{
    background-color: black;
    border-radius: 10px;
    min-height: 100px;
    color:white;
    display: flex;
    flex-direction: row;
    padding: 8px;
}
.title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.activegame >div{
    flex:1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.activegame .time{
    
    display: flex;
}

.activegame .time span{
    margin: auto;
    font-size: larger;
    font-weight: bold;
}
.wallet{
    padding: 16px;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 5px;
}
.pairs{
    padding: 16px;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 5px;
    border-color: aqua;
    border-width: 1;
    border-style: dotted;
    
}
.pairs >p{
font-size: 36px;
margin-left: 16px;
margin-right: 16px;
}

.failedgame{
    color:red;
    font-size: 14px;
    font-weight: bold;
}
.waitpair {
    color:darkgreen;
    font-size: 15px;
    font-weight: bold;
}