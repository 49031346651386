.input_wrap{
  flex:1;
  margin-left: 49px;
  margin-right: 37px;
}
.inputtext{
    height: 45px;
    width: 100%;
    border-radius: 10px;
    border-color: #D9D9D9;
    border-style: solid;
    border-width: 1px;
    display: flex;
    margin-top: 16px;
  }
  .inputtext input, .inputtext select{
    font-size: 15px;
    font-family: 'Inter';
    font-weight: 400;
    color:#A19C9C;
    padding-left: 10px;
    border-style: none;
    flex:1;
  }
  
  .inputtext input:focus, .inputtext select:focus{
    font-size: 15px;
    font-family: 'Inter';
    font-weight: 400;
    color:#A19C9C;
    padding-left: 10px;
    border-style: none;
    flex:1;
    background-color: #61dafb;
    width: 80%;
    transition: ease-in-out, width .35s ease-in-out;
    background-color: transparent;
      border: 0px solid;
      outline: none;
  }
  .inputtext .lefticon{
    background-color:  #85D4F56B;
    color:#13B7FD ;
    padding:auto ;
    width: 60px;
    height: 100%;
    text-align: center;
    display: flex;      
  }
  .inputtext .lefticon svg{
    margin: auto;
  }
  .inputtext .righticon{
    display: flex; 
    width: 60px;
  }
  .inputtext .righticon svg{
    margin: auto;
  }

  @media screen and (max-width: 700px){
    .input_wrap{
      flex:1;
      margin-left: 5px;
      margin-right: 5px;
    }
  }