.topAdmin{
    display: flex;
    flex-direction: row;
    
}
.sidebar{
    min-width:160px;
    border-color: black;
    border-width: 1px;
    border-style: solid;
    
}
.contentside{
    flex:1;
}
.groupWidget{
    padding: 8px;;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}
.groupWidget .widget{
    border-right-color: black;
    border-right-style: solid;
    border-right-width: 1px;
    min-width: 300px;
    margin: 8px;
    
    
}
.widget p:first-child{
font-size: 14px;
color: #AEA8A8;
}
.widget p:last-child{
    font-size: 27px;
    color:black;
    text-align: center;
    font-weight: bold;
    }

    .sidebar .menu ul{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
      .sidebar .menu li{
        list-style-type: none;
        margin-left: 17px;
        margin-right: 16px;
        
      }
      .sidebar ul a{
        text-decoration-line: none;
        color:#555659;
        font-size: 19px;
        line-height: 27.78px;
        font-weight: 500;
        font-family: Inter;
      }
     /*  .sidebar .underline{
        border-right-color: black;
        border-right-style: solid;
        border-right-width: 1px;
      } */