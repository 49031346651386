body{
  font-size: 14px;
  background-color: #F4F4F4;
}


.appWrap{
  width: 1440px;
  max-width: 80%;
  margin:auto;
}

.wrapper{
  width: 600px;
  margin-top: 72px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 72px;
  border-radius: 5px;
  max-width: 80%;
  background-color: white;
  padding: 16px;
}

.logoside{
  display: flex;
  flex-direction: row;
}
.homebar{
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-color: #9B9696;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.homebar img{
 
  height:50px;
  width: 50px;
   margin-top:15px;
   margin-left: 15px;

    
}
.homebar span{
  font-size: 20px;
  font-weight: bold;
  margin-top: 25px;
}
.homebar .menu{

}
.homebar .menu ul{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.homebar .menu li{
  list-style-type: none;
  margin-left: 17px;
  margin-right: 16px;
}
.homebar ul a{
  text-decoration-line: none;
  color:#555659;
  font-size: 19px;
  line-height: 27.78px;
  font-weight: 500;
  font-family: Inter;
}
button{
  height: 40px;
 
 min-width: 98px;
 
 border-radius: 25px;
 font-size: 14px;
 outline: none;
 transition: all .3s ease-in-out;
 border-width: 0px;
 padding-left: 20px;
 padding-right: 20px;
}
.btn_primary{
  color:white;
  background-color: #13B7FD;
  font-family: 'Inter';
  font-weight: bold;
 }
 .btn_sec{
  color:#13B7FD;
  background-color:white; 
  border-color: #13B7FD;
  border-width: 1px;
  border-style: solid;
  font-family: 'Inter';
  font-weight: bold;
 }
 .btn_pry_red{
  color:white;
  background-color: red;
  font-family: 'Inter';
  font-weight: bold;
 }
 .btn_sec_red{
  color:red;
  background-color:white; 
  border-color: red;
  border-width: 1px;
  border-style: solid;
  font-family: 'Inter';
  font-weight: bold;
 }
 .btn_default{

 }
 .btn_sec_default{

 }
 .footer{
  background-color: #ebedf3;
  color: rgb(36, 32, 32);
  text-align: center;
  padding: 8px;
  display: flex;
  font-size: 15px;
  font-weight: bold;
 }
 .footer >div{
  flex:1
 }
 .underline{
  padding-left: 10px;
  padding-right: 10px;
  border-bottom-width: 4px;
  border-bottom-style: solid;
  border-bottom-color: #13B7FD;

  min-height: 40px;
  color: #13B7FD;
  margin-bottom: 5px;
  cursor:default;

}
.innerBox{
  padding: 16px;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 5px;
  margin-bottom: 8px;
  flex-wrap: wrap;
}
.timeIndicator{
  width: 240px;
  background-color: #F20F0F;
  color:white;
  
  display: flex;
  margin: auto;
  justify-content:center;
  border-radius: 25px;
}
.timeIndicator span:first-child{
  font-size: 12px;
  margin-right: 8px;
}
.timeIndicator span:last-child{
  font-size: 24px;
}

.dialog{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #9B969690;
  z-index: 500;
  top: 0px;
  left: 0px;
  display: none;
}
.dialog .content{
 width: 70%;
 margin: auto;
}
.show{
  display: flex;
}
tr:nth-child(even) {
  background-color: rgba(150, 212, 212, 0.4);
}

th:nth-child(even),td:nth-child(even) {
  background-color: rgba(150, 212, 212, 0.4);
}
table{
  width: 80%;
}

tr {
  border-bottom: 1px solid #ddd;
  padding: 10px ;
}
td{
  padding: 5px;
}
tr:hover {background-color: #D6EEEE;}



@media screen and (max-width: 700px){
  .homebar{
    flex-direction: column;
  }

  .wrapper{
    width: 100%;
    max-width: 100%;
    padding: 0px;
    margin-left: 0px;
  }

  .appWrap{
    
    max-width: 95%;
    margin:auto;
  }

  .innerBox >a{
    margin: 5px;
  }
  .homebar .menu li{
    list-style-type: none;
    margin-left: 5px;
    margin-right: 5px;
  }
  .homebar ul a{
    text-decoration-line: none;
    color:#555659;
    font-size: 16px;
    line-height: 27.78px;
    font-weight: 500;
    font-family: Inter;
  }
}

@media print
{    
  .no-print, .no-print *
  {
      display: none !important;
  }
}

.place-copy{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px dashed #6e728a;
  padding: 8px;
  font-size: 14px;
  color: #333;
  font-weight: 500;
  border-radius: 4px;
}

.place-copy .tap-copy{
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  color: #a2a4ac;
}

.blink_me {
  animation: blinker 1s linear infinite;
}

.blink_me2 {
  animation: blinker 3s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.pagenate{
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  border-color: #13B7FD;
  border-width: 1px;
  border-style: dotted;
  margin-top: 16px;
  margin-bottom: 16px;
}

.pagenate div {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  cursor: pointer;
}
.pagenate div.active {
  background-color: #4CAF50;
  color: white;
}

.pagenate div:hover:not(.active) {background-color: #ddd;}