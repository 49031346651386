.home_line1{
    display: flex;
    flex-direction: row;
}

.home_line1>div{
    flex:1;
    margin: 62px;
}
.home_line2{
    border-color: #13B7FD;
    border-width: 10px;
    border-style: solid;
    padding: 16px;
}
.subjecthead{
    text-align: center;
}
.subjecthead span{
    background-color: #13B7FD;
    border-radius: 5;
    font-size: larger;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.subjecthead p{
padding-left: 100px;
padding-right: 100px;
}

.home_boxes{
    display: flex;
    flex-direction: row;
}

.home_boxes .box{
flex: 1;
border-radius: 5px;
border-color: #13B7FD;
border-width: 1px;
border-style: solid;
margin: 5px;
padding: 5px;
}
.home_boxes .box h2{
    text-align: center;
}
@media screen and (max-width: 700px){
    .home_boxes{
      display: flex;
      flex-direction: column;
    }
    .home_line1{
        display: flex;
      flex-direction: column;
    }

    .home_line1 div:last-child{
        display: none;
    }
  }