.pairback{
    background-image: url("../images/pairbackgorund.png");
    margin: 16px;
    min-height: 500px;
    background-size: cover;
    padding: 16px;
}

.pairmember{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    margin-left: 16px;
    margin-right: 16px;
    text-align: center;
}
.pairmember .avatar{
    border-radius: 40px;
    padding: 32px;
    background-color: white;
    text-align: center;
}
.pairmember .playersocre{
    font-size: 24px;
    font-weight: bold;
}
.pairmember .playerphone{
    font-weight: bold;
}
.pairmember .turn{
    color: red;
    font-weight: bold;
}
 .cards{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.cards .card{
    width: 120px;
    height: 140px;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    margin: 8px;
    display: flex;
    flex-direction: column;
    color:white;
    padding: 5px;
}
.card .cardtop{
    display: flex;
    justify-content: space-between;
}
.card .cardpick{
    display: flex;
    flex:1;
    
}
.card .cardpick span{
    margin: auto;
    font-size: 35px;
}
.playermiddle{
    font-size: 16px;
}
.playermiddle p span:last-child{
font-weight: bold;
margin-left: 8px;
}
.playermiddle .splashanim{

}
.cashoutbtn button{
 border-radius: 2px;
}
.cashoutbtn button:first-child{
    background-color: #F90B0BF2;
    color: white;
   }
   .cashoutbtn button:last-child{
    background-color: #11790F;
    color: white;
   }
   .cashoutbtn button:last-child span:first-child{
    font-size: 10px;
    margin: 10px;
    color:chartreuse;
   }
   .waiting{
    font-weight: bold;
   }
   .gameover{
    text-align: center;
    color:red;
   }
   .gametimenote{
    color:red;
    font-size: 16px;
    font-weight: bold;
   }

   @media screen and (max-width: 800px){
    .cards .card{
        width: 60px;
        height: 80px;
        border-radius: 3px;
        border-width: 1px;
        border-style: solid;
        margin: 8px;
        display: flex;
        flex-direction: column;
        color:white;
        padding: 3px;
    }

    .card .cardpick span{
        margin: auto;
        font-size: 25px;
    }
    .playermiddle{
        font-size: 12px;
    }

    .pairmember .avatar{
        border-radius: 25px;
        padding: 20px;
        background-color: white;
        text-align: center;
    }

    .gametimenote{
        color:red;
        font-size: 14px;
        font-weight: bold;
       }

       .card .cardtop span:first-child{
        font-size: 12px;
    }
  }